var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopBar',{attrs:{"title":_vm.title}},[_c('template',{slot:"actions"},[(_vm.canAddCompany())?_c('v-btn',{staticClass:"mb-0",attrs:{"color":"primary","to":{ name: 'Company Create' }}},[_vm._v(" "+_vm._s(_vm.$t('btn.add_company'))+" ")]):_vm._e()],1)],2),_c('v-container',{attrs:{"id":"company-list","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"inline":"","dark":_vm.$vuetify.theme.dark,"color":"primary","icon":"mdi-domain"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-card-title',{staticClass:"pt-0 pb-2"},[_c('div',[_vm._v(_vm._s(_vm.$t('company.list.title')))])])]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1 my-3",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.companyList,"options":_vm.paginationOptions,"hide-default-footer":"","dark":_vm.$vuetify.theme.dark},on:{"update:sort-by":_vm.onSortBy,"update:sort-desc":_vm.onSortDesc},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('company.list.filter.name'),"value":_vm.filters.name},on:{"filter-list":_vm.filterByName}})],1)]}},{key:"header.siret",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('company.list.filter.siret'),"value":_vm.filters.siret},on:{"filter-list":_vm.filterBySiret}})],1)]}},{key:"header.type",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex text-no-wrap"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('company.list.filter.type'),"value":_vm.filters.type},on:{"filter-list":_vm.filterByType}})],1)]}},{key:"header.contactLastName",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('company.list.filter.main_manager_last_name'),"value":_vm.filters.mainManagerLastName},on:{"filter-list":_vm.filterByMainManagerLastName}})],1)]}},{key:"header.trainee",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:" text-no-wrap"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.contactFirstName",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('ListTextFilter',{attrs:{"translation":_vm.$t('company.list.filter.main_manager_first_name'),"value":_vm.filters.mainManagerFirstName},on:{"filter-list":_vm.filterByMainManagerFirstName}})],1)]}},{key:"item.contactFirstName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.getFirstContactFirstName(item.managers))+" ")])]}},{key:"item.contactLastName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.getFirstContactLastName(item.managers))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-center ma-2 rounded"},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"success"}},[_c('v-icon',{on:{"click":function($event){return _vm.goToCompanyForm(item)}}},[_vm._v(" mdi-pencil ")])],1)],1)])]}},{key:"footer",fn:function(){return [_c('Pagination',{attrs:{"pagination-options":_vm.paginationOptions,"nb-pages":_vm.nbPages},on:{"changePaginationOptions":function($event){_vm.paginationOptions = $event}}})]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }