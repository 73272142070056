







































































































































import { defineComponent, ref } from '@vue/composition-api'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { mapActions, mapState } from 'vuex'
import { QueryParams } from '@/store/api-plateform-utils'
import { Company } from '@/api/interfaces/company'
import { Manager } from '@/api/interfaces/manager'
import {
  usePagination,
  PaginationOptions,
  parseQuery,
} from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import { userCanAccess } from '@/composables/UserGrant'

export default defineComponent({
  setup (props, ctx) {
    const canAddCompany = () => userCanAccess('Company Create')
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.company.totalItems
    }
    const filters = ref({
      name: query.name || '',
      type: query.type || '',
      siret: query.siret || '',
      mainManagerLastName: query.mainManagerLastName || '',
      mainManagerFirstName: query.mainManagerFirstName || '',
    })
    delete query.name
    delete query.type
    delete query.siret
    delete query.mainManagerLastName
    delete query.mainManagerFirstName
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions

    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      filters,
      canAddCompany,
    }
  },
  name: 'CompanyList',
  components: {
    TopBar,
    ListTextFilter: () => import('@/components/base/ListTextFilter.vue'),
    Pagination,
  },
  data () {
    return {
      title: this.$t('company.title'),
      headers: [
        {
          text: this.$t('company.list.headers.company'),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t('company.list.headers.siret'),
          value: 'siret',
        },
        {
          text: this.$t('company.list.headers.type'),
          value: 'type',
        },
        {
          text: this.$t('company.list.headers.manager.lastName'),
          value: 'contactLastName',
        },
        {
          text: this.$t('company.list.headers.manager.firstName'),
          value: 'contactFirstName',
        },
        {
          text: this.$t('company.list.headers.phone'),
          value: 'phone',
        },
        {
          text: this.$t('company.list.headers.email'),
          value: 'email',
        },
        {
          text: this.$t('company.list.headers.address'),
          value: 'address',
        },
        {
          text: this.$t('company.list.headers.nbTrainee'),
          value: 'countNeedTrainings',
        },
        {
          text: this.$t('company.list.headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapState('company', {
      companyList: 'list',
      loading: 'loading',
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...this.filters,
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
  },
  created () {
    this.load(this.baseRequest)
  },
  watch: {
    filters: {
      handler () {
        this.loadData()
        this.paginationOptions.page = 1
        this.load(this.baseRequest)
      },
      deep: true,
    },
    paginationOptions: {
      handler () {
        this.loadData()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('company', {
      load: 'load',
    }),
    goToCompanyForm (item: Company) {
      if (item.id) {
        this.$router.push({
          name: 'Company Edit',
          params: { idCompany: item.id.toString() },
        })
      }
    },
    loadData () {
      this.addParamsToLocation()
      this.load(this.baseRequest)
    },
    addParamsToLocation () {
      let query: any = {
        ...this.filters,
        ...this.paginationOptions,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )

      router.replace({ name: 'Company List', query: query })
    },
    getFirstContactLastName (managers: Array<Manager>) {
      let lastName
      managers.forEach((manager: Manager) => {
        if (manager.main === true) {
          lastName = manager.lastName
        }
      })

      return lastName
    },
    getFirstContactFirstName (managers: Array<Manager>) {
      let firstName
      managers.forEach((manager: Manager) => {
        if (manager?.main === true) {
          firstName = manager.firstName
        }
      })

      return firstName
    },
    filterByName (value: string) {
      this.filters.name = value
    },
    filterBySiret (value: string) {
      this.filters.siret = value
    },
    filterByType (value: string) {
      this.filters.type = value
    },
    filterByMainManagerLastName (value: string) {
      this.filters.mainManagerLastName = value
    },
    filterByMainManagerFirstName (value: string) {
      this.filters.mainManagerFirstName = value
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Company Edit' || to.name === 'Company Create') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
